@font-face {
  font-family: "Jura";
  src: url("../../../styles/fonts/Jura/static/Jura-Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Jura";
  src: url("../../../styles/fonts/Jura/static/Jura-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

.card {
  height: auto;
  width: 20%;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.05) 0%,
    rgba(83, 113, 196, 0.05) 11.79%,
    rgba(87, 115, 198, 0.05) 21.38%,
    rgba(93, 116, 200, 0.05) 29.12%,
    rgba(101, 119, 203, 0.05) 35.34%,
    rgba(110, 122, 207, 0.05) 40.37%,
    rgba(121, 125, 211, 0.05) 44.56%,
    rgba(133, 129, 215, 0.05) 48.24%,
    rgba(145, 133, 220, 0.05) 51.76%,
    rgba(159, 137, 225, 0.05) 55.44%,
    rgba(172, 141, 230, 0.05) 59.63%,
    rgba(186, 145, 235, 0.05) 64.66%,
    rgba(199, 150, 240, 0.05) 70.88%,
    rgba(212, 154, 245, 0.05) 78.62%,
    rgba(225, 157, 250, 0.05) 88.21%,
    rgba(236, 161, 254, 0.05) 100%
  );
  margin-bottom: 3vh;
  border-radius: 20px;
  border-style: solid;
  border-width: 0.1px;
  border-color: #9e9e9e;
  margin-right: 1vw;
  display: flex;
  flex-direction: column;
  scrollbar-color: #9921e8;
  text-align: left;
}

.card-mobile {
  height: auto;
  width: 100%;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.05) 0%,
    rgba(83, 113, 196, 0.05) 11.79%,
    rgba(87, 115, 198, 0.05) 21.38%,
    rgba(93, 116, 200, 0.05) 29.12%,
    rgba(101, 119, 203, 0.05) 35.34%,
    rgba(110, 122, 207, 0.05) 40.37%,
    rgba(121, 125, 211, 0.05) 44.56%,
    rgba(133, 129, 215, 0.05) 48.24%,
    rgba(145, 133, 220, 0.05) 51.76%,
    rgba(159, 137, 225, 0.05) 55.44%,
    rgba(172, 141, 230, 0.05) 59.63%,
    rgba(186, 145, 235, 0.05) 64.66%,
    rgba(199, 150, 240, 0.05) 70.88%,
    rgba(212, 154, 245, 0.05) 78.62%,
    rgba(225, 157, 250, 0.05) 88.21%,
    rgba(236, 161, 254, 0.05) 100%
  );
  margin-bottom: 3vh;
  border-radius: 20px;
  border-style: solid;
  border-width: 0.1px;
  border-color: #9e9e9e;
  margin-right: 1vw;
  display: flex;
  flex-direction: column;
  scrollbar-color: #9921e8;
  text-align: left;
}
.profile_icon {
  width: 33px;
  height: 33px;
  background-size: contain;
  margin-top: 5%;
  margin-left: 5%;
  background-color: #fff;
}

.company_information_container {
  display: flex;
  flex-direction: column;
}

.company_information_location_cont {
  border-radius: 4px;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.05) 0%,
    rgba(83, 113, 196, 0.05) 11.79%,
    rgba(87, 115, 198, 0.05) 21.38%,
    rgba(93, 116, 200, 0.05) 29.12%,
    rgba(101, 119, 203, 0.05) 35.34%,
    rgba(110, 122, 207, 0.05) 40.37%,
    rgba(121, 125, 211, 0.05) 44.56%,
    rgba(133, 129, 215, 0.05) 48.24%,
    rgba(145, 133, 220, 0.05) 51.76%,
    rgba(159, 137, 225, 0.05) 55.44%,
    rgba(172, 141, 230, 0.05) 59.63%,
    rgba(186, 145, 235, 0.05) 64.66%,
    rgba(199, 150, 240, 0.05) 70.88%,
    rgba(212, 154, 245, 0.05) 78.62%,
    rgba(225, 157, 250, 0.05) 88.21%,
    rgba(236, 161, 254, 0.05) 100%
  );
  width: auto;
  height: 30px;
  flex-shrink: 0;
  margin-left: 10%;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
  display: flex;
}

.company_information_container_text {
  color: #fff;
  font-family: "Jura";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
  align-self: center;
}

.company_name {
  color: #fff;
  font-family: "Jura";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.48px;
  margin-top: 8%;
  margin-left: 2%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.job_title {
  overflow: hidden;
  color: rgba(255, 255, 255, 0.8);
  text-overflow: ellipsis;
  font-family: "Jura";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 112.5% */
  letter-spacing: 0.32px;
  margin-left: 3%;
  margin-top: 5%;
}

.job_description {
  overflow: hidden;
  color: rgba(255, 255, 255, 0.8);
  text-overflow: ellipsis;
  font-family: "Jura";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: 0.24px;
  margin-top: 1%;
  margin-left: 3%;
  margin-right: 3%;
}

.job_location_icon {
  margin-top: 1%;
  margin-left: 3%;
}

.job_location {
  margin-top: 1%;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 8;
  color: #fafafa;
  margin-left: 1%;
  font-weight: 400;
  text-align: left;
}

.line_break {
  height: 0.1%;
  width: 94%;
  background-color: #9e9e9e;
  margin-top: 3%;
  margin-left: 3%;
  margin-right: 3%;
}

.job_info {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 8;
  color: #9e9e9e;
  margin-top: 3%;
  margin-left: 3%;
  font-weight: 400;
  text-align: left;
}

.job_info_icon {
  margin-top: 3%;
  margin-left: 3%;
}

.job_apply_button {
  margin-top: 5%;
  margin-left: 3%;
  margin-right: 3%;
  height: auto;
  border-radius: 25px;
  background-color: #9921e8;
  background-image: linear-gradient(315deg, #9921e8 0%, #5f72be 74%);
  justify-content: center;
  width: auto;
  display: flex;
}

.apply_icon {
  height: 50px;
  width: 50px;
}

.apply_text {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #fafafa;
  margin-top: 3%;
}

.job_bookmark_icon {
  margin-top: 10%;
}

.job_share_icon {
  margin-top: 10%;
  margin-left: 2%;
}

.job_waitlist_button {
  margin-top: 5%;
  margin-left: 3%;
  margin-right: 3%;
  border-radius: 50px;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.1) 0%,
    rgba(83, 113, 196, 0.1) 11.79%,
    rgba(87, 115, 198, 0.1) 21.38%,
    rgba(93, 116, 200, 0.1) 29.12%,
    rgba(101, 119, 203, 0.1) 35.34%,
    rgba(110, 122, 207, 0.1) 40.37%,
    rgba(121, 125, 211, 0.1) 44.56%,
    rgba(133, 129, 215, 0.1) 48.24%,
    rgba(145, 133, 220, 0.1) 51.76%,
    rgba(159, 137, 225, 0.1) 55.44%,
    rgba(172, 141, 230, 0.1) 59.63%,
    rgba(186, 145, 235, 0.1) 64.66%,
    rgba(199, 150, 240, 0.1) 70.88%,
    rgba(212, 154, 245, 0.1) 78.62%,
    rgba(225, 157, 250, 0.1) 88.21%,
    rgba(236, 161, 254, 0.1) 100%
  );
  justify-content: center;
  display: flex;
  width: 60%;
  height: 40px;
  flex-shrink: 0;
  border-radius: 0px;
}

.job_save_button {
  margin-top: 5%;
  margin-left: 3%;
  margin-right: 3%;
  border-radius: 50px;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.1) 0%,
    rgba(83, 113, 196, 0.1) 11.79%,
    rgba(87, 115, 198, 0.1) 21.38%,
    rgba(93, 116, 200, 0.1) 29.12%,
    rgba(101, 119, 203, 0.1) 35.34%,
    rgba(110, 122, 207, 0.1) 40.37%,
    rgba(121, 125, 211, 0.1) 44.56%,
    rgba(133, 129, 215, 0.1) 48.24%,
    rgba(145, 133, 220, 0.1) 51.76%,
    rgba(159, 137, 225, 0.1) 55.44%,
    rgba(172, 141, 230, 0.1) 59.63%,
    rgba(186, 145, 235, 0.1) 64.66%,
    rgba(199, 150, 240, 0.1) 70.88%,
    rgba(212, 154, 245, 0.1) 78.62%,
    rgba(225, 157, 250, 0.1) 88.21%,
    rgba(236, 161, 254, 0.1) 100%
  );
  justify-content: center;
  align-items: center;
  display: flex;
  width: 20%;
  height: 40px;
  flex-shrink: 0;
  border-radius: 0px;
}

.waitlist_icon {
  margin-top: 4%;
  margin-right: 3%;
}
