@font-face {
  font-family: "Jura";
  src: url("../../styles/fonts/Jura/static/Jura-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Jura";
  src: url("../../styles/fonts/Jura/static/Jura-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

.search-bar {
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  height: 15%;
  width: 94%;
  overflow: hidden;
  transition: height 0.3s ease;
  margin-left: 3%;
  margin-right: 3%;
  border-radius: 8px;
  margin-top: 0.5%;
}

.search-bar-mobile {
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  height: 25%;
  width: 94%;
  overflow: hidden;
  transition: height 0.3s ease;
  margin-left: 3%;
  margin-right: 3%;
  border-radius: 8px;
  margin-top: 0.5%;
}

.search-bar.expanded {
  height: 35%;
  border-radius: 10px;
}

.search-bar-input {
  flex: 1;
  height: 100%;
  padding: 0 10px;
  border: none;
  outline: none;
  font-size: 16px;
  color: white;
  background-color: transparent;
  transition: flex 0.3s ease; /* Add transition for flex property */
}

.search-bar-input-new {
  display: flex;
  height: 100%;
  width: 100%;
  transition: flex 0.3s ease;
  background: transparent;
  border-width: 0;
  color: rgba(255, 255, 255, 0.5);
  font-family: "Jura";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}

.search-bar button {
  height: 100%;
  padding: 0 10px;
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
}

.search-bar-mobile button {
  height: 100%;
  padding: 0 10px;
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
}

.search-bar-container {
  width: 90%;
  height: 25%;
  flex-shrink: 0;
  border-radius: 16px;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.05) 0%,
    rgba(83, 113, 196, 0.05) 11.79%,
    rgba(87, 115, 198, 0.05) 21.38%,
    rgba(93, 116, 200, 0.05) 29.12%,
    rgba(101, 119, 203, 0.05) 35.34%,
    rgba(110, 122, 207, 0.05) 40.37%,
    rgba(121, 125, 211, 0.05) 44.56%,
    rgba(133, 129, 215, 0.05) 48.24%,
    rgba(145, 133, 220, 0.05) 51.76%,
    rgba(159, 137, 225, 0.05) 55.44%,
    rgba(172, 141, 230, 0.05) 59.63%,
    rgba(186, 145, 235, 0.05) 64.66%,
    rgba(199, 150, 240, 0.05) 70.88%,
    rgba(212, 154, 245, 0.05) 78.62%,
    rgba(225, 157, 250, 0.05) 88.21%,
    rgba(236, 161, 254, 0.05) 100%
  );
  backdrop-filter: blur(20px);
  margin-left: 5%;
  margin-right: 5%;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.search-bar-container-mobile {
  width: 100%;
  height: 25%;
  flex-shrink: 0;
  border-radius: 16px;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.05) 0%,
    rgba(83, 113, 196, 0.05) 11.79%,
    rgba(87, 115, 198, 0.05) 21.38%,
    rgba(93, 116, 200, 0.05) 29.12%,
    rgba(101, 119, 203, 0.05) 35.34%,
    rgba(110, 122, 207, 0.05) 40.37%,
    rgba(121, 125, 211, 0.05) 44.56%,
    rgba(133, 129, 215, 0.05) 48.24%,
    rgba(145, 133, 220, 0.05) 51.76%,
    rgba(159, 137, 225, 0.05) 55.44%,
    rgba(172, 141, 230, 0.05) 59.63%,
    rgba(186, 145, 235, 0.05) 64.66%,
    rgba(199, 150, 240, 0.05) 70.88%,
    rgba(212, 154, 245, 0.05) 78.62%,
    rgba(225, 157, 250, 0.05) 88.21%,
    rgba(236, 161, 254, 0.05) 100%
  );
  backdrop-filter: blur(20px);
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.search-bar-container-header {
  color: #fff;
  font-family: "Jura";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.48px;
  margin-left: 3%;
}

.search-bar-container-header-mobile {
  color: #fff;
  font-family: "Jura";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.48px;
  margin-left: 3%;
  text-align: left;
  margin-bottom: 2vh;
}

.search-bar-container-enter-text {
  color: #fff;
  font-family: "Jura";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.48px;
  margin-left: 3%;
  margin-top: 0.5%;
  opacity: 0.5;
  white-space: nowrap;
  display: inline;
}

.search-bar-container-enter-text-light {
  opacity: 0.5;
  color: #fff;
  font-family: "Jura";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
}

.search-bar-input-new-mobile {
  display: flex;
  height: 100%;
  width: 100%;
  transition: flex 0.3s ease;
  background: transparent;
  border-width: 0;
  color: rgba(255, 255, 255, 0.5);
  font-family: "Jura";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}
