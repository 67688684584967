@font-face {
  font-family: "Jura";
  src: url("../styles/fonts/Jura/static/Jura-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Jura";
  src: url("../styles/fonts/Jura/static/Jura-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

.back-button-cont {
  display: flex;
  flex-direction: "row";
  margin-left: 5%;
  margin-bottom: 1%;
  background-color: transparent;
  border-width: 0.1px;
}

.back-button-button {
  background-color: transparent;
  border-width: 0.1px;
}

.back-button-text {
  color: #fff;
  font-family: "Jura";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.32px;
  margin-left: 1%;
}

.description_container {
  width: 90%;
  height: 35%;
  border-radius: 16px;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.05) 0%,
    rgba(83, 113, 196, 0.05) 11.79%,
    rgba(87, 115, 198, 0.05) 21.38%,
    rgba(93, 116, 200, 0.05) 29.12%,
    rgba(101, 119, 203, 0.05) 35.34%,
    rgba(110, 122, 207, 0.05) 40.37%,
    rgba(121, 125, 211, 0.05) 44.56%,
    rgba(133, 129, 215, 0.05) 48.24%,
    rgba(145, 133, 220, 0.05) 51.76%,
    rgba(159, 137, 225, 0.05) 55.44%,
    rgba(172, 141, 230, 0.05) 59.63%,
    rgba(186, 145, 235, 0.05) 64.66%,
    rgba(199, 150, 240, 0.05) 70.88%,
    rgba(212, 154, 245, 0.05) 78.62%,
    rgba(225, 157, 250, 0.05) 88.21%,
    rgba(236, 161, 254, 0.05) 100%
  );
  backdrop-filter: blur(20px);
  margin-left: 5%;
  margin-right: 5%;
  flex-direction: column;
}

.description_job_info {
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
}

.description_icon {
  height: 75px;
  width: 75px;
  margin-left: 2%;
  margin-top: 2%;
}

.description_job_title {
  flex-direction: column;
}

.description_job_title_heading {
  color: #fff;
  font-family: "Jura";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.48px;
  margin-left: 5%;
  margin-top: 5vh;
  white-space: nowrap;
}

.description_description_cont {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  max-height: 120px; /* Set a maximum height for the container */
  overflow-y: hidden;
  margin-top: 1%;
  margin-left: 2%;
  margin-right: 2%;
}

.description_text {
  color: rgba(255, 255, 255, 0.8);
  overflow-y: auto;
  font-family: "Jura";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.32px;
  text-align: left;
}

::-webkit-scrollbar {
  width: 2px; /* Adjust the width of the scrollbar */
}

::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Change the color of the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: #fff; /* Set the color of the thumb */
}

.description_button_container {
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
}
.description_apply_button_container {
  width: 8%;
  height: 4%;
  flex-shrink: 0;
  border-radius: 8px;
  background: linear-gradient(135deg, #7b3afd 0%, #c962ed 100%);
  margin-top: 1%;
  margin-left: 2%;
}

.description_save_button_container {
  width: 8%;
  height: 4%;
  flex-shrink: 0;
  border-radius: 8px;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.1) 0%,
    rgba(83, 113, 196, 0.1) 11.79%,
    rgba(87, 115, 198, 0.1) 21.38%,
    rgba(93, 116, 200, 0.1) 29.12%,
    rgba(101, 119, 203, 0.1) 35.34%,
    rgba(110, 122, 207, 0.1) 40.37%,
    rgba(121, 125, 211, 0.1) 44.56%,
    rgba(133, 129, 215, 0.1) 48.24%,
    rgba(145, 133, 220, 0.1) 51.76%,
    rgba(159, 137, 225, 0.1) 55.44%,
    rgba(172, 141, 230, 0.1) 59.63%,
    rgba(186, 145, 235, 0.1) 64.66%,
    rgba(199, 150, 240, 0.1) 70.88%,
    rgba(212, 154, 245, 0.1) 78.62%,
    rgba(225, 157, 250, 0.1) 88.21%,
    rgba(236, 161, 254, 0.1) 100%
  );
  margin-top: 1%;
  margin-left: 2%;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
}
.description_apply_button_text {
  width: auto;
  color: #fff;
  text-align: center;
  font-family: "Jura";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.32px;
  margin-top: 5%;
}
.description_save_button_text {
  width: auto;
  color: #fff;
  text-align: center;
  font-family: "Jura";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.32px;
  margin-left: 5%;
  margin-top: 5%;
}

.generate_cv_container {
  margin-top: 1vh;
  width: 90%;
  height: 10vh;
  border-radius: 16px;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.05) 0%,
    rgba(83, 113, 196, 0.05) 11.79%,
    rgba(87, 115, 198, 0.05) 21.38%,
    rgba(93, 116, 200, 0.05) 29.12%,
    rgba(101, 119, 203, 0.05) 35.34%,
    rgba(110, 122, 207, 0.05) 40.37%,
    rgba(121, 125, 211, 0.05) 44.56%,
    rgba(133, 129, 215, 0.05) 48.24%,
    rgba(145, 133, 220, 0.05) 51.76%,
    rgba(159, 137, 225, 0.05) 55.44%,
    rgba(172, 141, 230, 0.05) 59.63%,
    rgba(186, 145, 235, 0.05) 64.66%,
    rgba(199, 150, 240, 0.05) 70.88%,
    rgba(212, 154, 245, 0.05) 78.62%,
    rgba(225, 157, 250, 0.05) 88.21%,
    rgba(236, 161, 254, 0.05) 100%
  );
  backdrop-filter: blur(20px);
  margin-left: 5%;
  margin-right: 5%;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.generate_cv_text {
  overflow: hidden;
  color: #fff;
  text-overflow: ellipsis;
  font-family: "Jura";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.32px;
  text-align: left;
  margin-left: 2%;
}

.generate_button {
  border-radius: 8px;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.1) 0%,
    rgba(83, 113, 196, 0.1) 11.79%,
    rgba(87, 115, 198, 0.1) 21.38%,
    rgba(93, 116, 200, 0.1) 29.12%,
    rgba(101, 119, 203, 0.1) 35.34%,
    rgba(110, 122, 207, 0.1) 40.37%,
    rgba(121, 125, 211, 0.1) 44.56%,
    rgba(133, 129, 215, 0.1) 48.24%,
    rgba(145, 133, 220, 0.1) 51.76%,
    rgba(159, 137, 225, 0.1) 55.44%,
    rgba(172, 141, 230, 0.1) 59.63%,
    rgba(186, 145, 235, 0.1) 64.66%,
    rgba(199, 150, 240, 0.1) 70.88%,
    rgba(212, 154, 245, 0.1) 78.62%,
    rgba(225, 157, 250, 0.1) 88.21%,
    rgba(236, 161, 254, 0.1) 100%
  );
  margin-left: 40vw;
  width: 245px;
  height: 40px;
  flex-shrink: 0;
}

.generate_courses_container {
  margin-top: 1vh;
  width: 90%;
  height: 40vh;
  border-radius: 16px;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.05) 0%,
    rgba(83, 113, 196, 0.05) 11.79%,
    rgba(87, 115, 198, 0.05) 21.38%,
    rgba(93, 116, 200, 0.05) 29.12%,
    rgba(101, 119, 203, 0.05) 35.34%,
    rgba(110, 122, 207, 0.05) 40.37%,
    rgba(121, 125, 211, 0.05) 44.56%,
    rgba(133, 129, 215, 0.05) 48.24%,
    rgba(145, 133, 220, 0.05) 51.76%,
    rgba(159, 137, 225, 0.05) 55.44%,
    rgba(172, 141, 230, 0.05) 59.63%,
    rgba(186, 145, 235, 0.05) 64.66%,
    rgba(199, 150, 240, 0.05) 70.88%,
    rgba(212, 154, 245, 0.05) 78.62%,
    rgba(225, 157, 250, 0.05) 88.21%,
    rgba(236, 161, 254, 0.05) 100%
  );
  backdrop-filter: blur(20px);
  margin-left: 5%;
  margin-right: 5%;
  flex-direction: column;
  display: flex;
}

.generate_content_container {
  margin-top: 1vh;
  width: 50%;
  height: 40vh;
  border-radius: 16px;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.05) 0%,
    rgba(83, 113, 196, 0.05) 11.79%,
    rgba(87, 115, 198, 0.05) 21.38%,
    rgba(93, 116, 200, 0.05) 29.12%,
    rgba(101, 119, 203, 0.05) 35.34%,
    rgba(110, 122, 207, 0.05) 40.37%,
    rgba(121, 125, 211, 0.05) 44.56%,
    rgba(133, 129, 215, 0.05) 48.24%,
    rgba(145, 133, 220, 0.05) 51.76%,
    rgba(159, 137, 225, 0.05) 55.44%,
    rgba(172, 141, 230, 0.05) 59.63%,
    rgba(186, 145, 235, 0.05) 64.66%,
    rgba(199, 150, 240, 0.05) 70.88%,
    rgba(212, 154, 245, 0.05) 78.62%,
    rgba(225, 157, 250, 0.05) 88.21%,
    rgba(236, 161, 254, 0.05) 100%
  );
  backdrop-filter: blur(20px);
  margin-left: 1%;
  margin-right: 5%;
  flex-direction: column;
  display: flex;
}

.content_header_info {
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
}

.content_header_text {
  color: #fff;
  font-family: "Jura";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.48px;
  margin-left: 5%;
  white-space: nowrap;
  margin-top: 2vh;
}

.content_container {
  border-radius: 8px;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.05) 0%,
    rgba(83, 113, 196, 0.05) 11.79%,
    rgba(87, 115, 198, 0.05) 21.38%,
    rgba(93, 116, 200, 0.05) 29.12%,
    rgba(101, 119, 203, 0.05) 35.34%,
    rgba(110, 122, 207, 0.05) 40.37%,
    rgba(121, 125, 211, 0.05) 44.56%,
    rgba(133, 129, 215, 0.05) 48.24%,
    rgba(145, 133, 220, 0.05) 51.76%,
    rgba(159, 137, 225, 0.05) 55.44%,
    rgba(172, 141, 230, 0.05) 59.63%,
    rgba(186, 145, 235, 0.05) 64.66%,
    rgba(199, 150, 240, 0.05) 70.88%,
    rgba(212, 154, 245, 0.05) 78.62%,
    rgba(225, 157, 250, 0.05) 88.21%,
    rgba(236, 161, 254, 0.05) 100%
  );
  width: 90%;
  height: 120px;
  flex-shrink: 0;
  margin-left: 5%;
  margin-top: 1%;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.content_img {
  height: 90%;
  width: 25%;
  border-radius: 15px;
  flex-shrink: 0;
  margin-left: 5%;
  object-fit: contain;
  align-self: center;
}

.content_title_container {
  flex-direction: column;
  display: flex;
  text-align: left;
}

.content_title_text_purp {
  color: #9e89e1;
  font-family: "Jura";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.32px;
}

.content_title_text_description {
  color: rgba(255, 255, 255, 0.8);
  font-family: "Jura";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.32px;
  text-overflow: ellipsis;
}

.explore-enter-text {
  color: #fff;
  font-family: "Jura";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.48px;
  opacity: 0.5;
  white-space: nowrap;
  display: inline;
  text-align: left;
}

/* Mobile Starts Here */
.back-button-cont {
  display: flex;
  flex-direction: "row";
  margin-left: 5%;
  margin-bottom: 1%;
  background-color: transparent;
  border-width: 0.1px;
}

.back-button-button {
  background-color: transparent;
  border-width: 0.1px;
}

.back-button-text {
  color: #fff;
  font-family: "Jura";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.32px;
  margin-left: 1%;
}

.description_container {
  width: 90%;
  height: 35%;
  border-radius: 16px;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.05) 0%,
    rgba(83, 113, 196, 0.05) 11.79%,
    rgba(87, 115, 198, 0.05) 21.38%,
    rgba(93, 116, 200, 0.05) 29.12%,
    rgba(101, 119, 203, 0.05) 35.34%,
    rgba(110, 122, 207, 0.05) 40.37%,
    rgba(121, 125, 211, 0.05) 44.56%,
    rgba(133, 129, 215, 0.05) 48.24%,
    rgba(145, 133, 220, 0.05) 51.76%,
    rgba(159, 137, 225, 0.05) 55.44%,
    rgba(172, 141, 230, 0.05) 59.63%,
    rgba(186, 145, 235, 0.05) 64.66%,
    rgba(199, 150, 240, 0.05) 70.88%,
    rgba(212, 154, 245, 0.05) 78.62%,
    rgba(225, 157, 250, 0.05) 88.21%,
    rgba(236, 161, 254, 0.05) 100%
  );
  backdrop-filter: blur(20px);
  margin-left: 5%;
  margin-right: 5%;
  flex-direction: column;
}

.description_container-mobile {
  width: 95%;
  height: 35%;
  border-radius: 16px;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.05) 0%,
    rgba(83, 113, 196, 0.05) 11.79%,
    rgba(87, 115, 198, 0.05) 21.38%,
    rgba(93, 116, 200, 0.05) 29.12%,
    rgba(101, 119, 203, 0.05) 35.34%,
    rgba(110, 122, 207, 0.05) 40.37%,
    rgba(121, 125, 211, 0.05) 44.56%,
    rgba(133, 129, 215, 0.05) 48.24%,
    rgba(145, 133, 220, 0.05) 51.76%,
    rgba(159, 137, 225, 0.05) 55.44%,
    rgba(172, 141, 230, 0.05) 59.63%,
    rgba(186, 145, 235, 0.05) 64.66%,
    rgba(199, 150, 240, 0.05) 70.88%,
    rgba(212, 154, 245, 0.05) 78.62%,
    rgba(225, 157, 250, 0.05) 88.21%,
    rgba(236, 161, 254, 0.05) 100%
  );
  backdrop-filter: blur(20px);
  flex-direction: column;
  margin-left: 2.5%;
  margin-right: 2.5%;
}

.description_job_info {
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
}

.description_icon {
  height: 75px;
  width: 75px;
  margin-left: 2%;
  margin-top: 2%;
}

.description_icon-mobile {
  height: 40px;
  width: 40px;
  margin-left: 2%;
  margin-top: 5%;
}

.description_job_title_heading {
  color: #fff;
  font-family: "Jura";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.48px;
  margin-left: 5%;
  margin-top: 5vh;
  white-space: nowrap;
}

.description_job_title_heading-mobile {
  color: #fff;
  font-family: "Jura";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
  margin-left: 2%;
  margin-top: 8%;
  white-space: nowrap;
}

.description_job_title_heading-2-mobile {
  color: #fff;
  font-family: "Jura";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.48px;
  margin-left: 2%;
  margin-top: 8%;
  text-align: left;
}

.description_description_cont {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  max-height: 120px; /* Set a maximum height for the container */
  overflow-y: hidden;
  margin-top: 1%;
  margin-left: 2%;
  margin-right: 2%;
}

.description_text {
  color: rgba(255, 255, 255, 0.8);
  overflow-y: auto;
  font-family: "Jura";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.32px;
  text-align: left;
}

::-webkit-scrollbar {
  width: 2px; /* Adjust the width of the scrollbar */
}

::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Change the color of the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: #fff; /* Set the color of the thumb */
}

.description_button_container {
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
}
.description_apply_button_container {
  width: 8%;
  height: 4%;
  flex-shrink: 0;
  border-radius: 8px;
  background: linear-gradient(135deg, #7b3afd 0%, #c962ed 100%);
  margin-top: 1%;
  margin-left: 2%;
}

.description_apply_button_container-mobile {
  width: 30%;
  height: 4%;
  flex-shrink: 0;
  border-radius: 8px;
  background: linear-gradient(135deg, #7b3afd 0%, #c962ed 100%);
  margin-top: 1%;
  margin-left: 2%;
}

.description_save_button_container {
  width: 8%;
  height: 4%;
  flex-shrink: 0;
  border-radius: 8px;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.1) 0%,
    rgba(83, 113, 196, 0.1) 11.79%,
    rgba(87, 115, 198, 0.1) 21.38%,
    rgba(93, 116, 200, 0.1) 29.12%,
    rgba(101, 119, 203, 0.1) 35.34%,
    rgba(110, 122, 207, 0.1) 40.37%,
    rgba(121, 125, 211, 0.1) 44.56%,
    rgba(133, 129, 215, 0.1) 48.24%,
    rgba(145, 133, 220, 0.1) 51.76%,
    rgba(159, 137, 225, 0.1) 55.44%,
    rgba(172, 141, 230, 0.1) 59.63%,
    rgba(186, 145, 235, 0.1) 64.66%,
    rgba(199, 150, 240, 0.1) 70.88%,
    rgba(212, 154, 245, 0.1) 78.62%,
    rgba(225, 157, 250, 0.1) 88.21%,
    rgba(236, 161, 254, 0.1) 100%
  );
  margin-top: 1%;
  margin-left: 2%;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
}

.description_save_button_container-mobile {
  width: 30%;
  height: 4%;
  flex-shrink: 0;
  border-radius: 8px;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.1) 0%,
    rgba(83, 113, 196, 0.1) 11.79%,
    rgba(87, 115, 198, 0.1) 21.38%,
    rgba(93, 116, 200, 0.1) 29.12%,
    rgba(101, 119, 203, 0.1) 35.34%,
    rgba(110, 122, 207, 0.1) 40.37%,
    rgba(121, 125, 211, 0.1) 44.56%,
    rgba(133, 129, 215, 0.1) 48.24%,
    rgba(145, 133, 220, 0.1) 51.76%,
    rgba(159, 137, 225, 0.1) 55.44%,
    rgba(172, 141, 230, 0.1) 59.63%,
    rgba(186, 145, 235, 0.1) 64.66%,
    rgba(199, 150, 240, 0.1) 70.88%,
    rgba(212, 154, 245, 0.1) 78.62%,
    rgba(225, 157, 250, 0.1) 88.21%,
    rgba(236, 161, 254, 0.1) 100%
  );
  margin-top: 1%;
  margin-left: 2%;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
}
.description_apply_button_text {
  width: auto;
  color: #fff;
  text-align: center;
  font-family: "Jura";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.32px;
  margin-top: 5%;
}
.description_save_button_text {
  width: auto;
  color: #fff;
  text-align: center;
  font-family: "Jura";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.32px;
  margin-left: 5%;
  margin-top: 5%;
}

.generate_cv_container {
  margin-top: 1vh;
  width: 90%;
  height: 10vh;
  border-radius: 16px;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.05) 0%,
    rgba(83, 113, 196, 0.05) 11.79%,
    rgba(87, 115, 198, 0.05) 21.38%,
    rgba(93, 116, 200, 0.05) 29.12%,
    rgba(101, 119, 203, 0.05) 35.34%,
    rgba(110, 122, 207, 0.05) 40.37%,
    rgba(121, 125, 211, 0.05) 44.56%,
    rgba(133, 129, 215, 0.05) 48.24%,
    rgba(145, 133, 220, 0.05) 51.76%,
    rgba(159, 137, 225, 0.05) 55.44%,
    rgba(172, 141, 230, 0.05) 59.63%,
    rgba(186, 145, 235, 0.05) 64.66%,
    rgba(199, 150, 240, 0.05) 70.88%,
    rgba(212, 154, 245, 0.05) 78.62%,
    rgba(225, 157, 250, 0.05) 88.21%,
    rgba(236, 161, 254, 0.05) 100%
  );
  backdrop-filter: blur(20px);
  margin-left: 5%;
  margin-right: 5%;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.generate_cv_container-mobile {
  margin-top: 1vh;
  width: 95%;
  height: auto;
  border-radius: 16px;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.05) 0%,
    rgba(83, 113, 196, 0.05) 11.79%,
    rgba(87, 115, 198, 0.05) 21.38%,
    rgba(93, 116, 200, 0.05) 29.12%,
    rgba(101, 119, 203, 0.05) 35.34%,
    rgba(110, 122, 207, 0.05) 40.37%,
    rgba(121, 125, 211, 0.05) 44.56%,
    rgba(133, 129, 215, 0.05) 48.24%,
    rgba(145, 133, 220, 0.05) 51.76%,
    rgba(159, 137, 225, 0.05) 55.44%,
    rgba(172, 141, 230, 0.05) 59.63%,
    rgba(186, 145, 235, 0.05) 64.66%,
    rgba(199, 150, 240, 0.05) 70.88%,
    rgba(212, 154, 245, 0.05) 78.62%,
    rgba(225, 157, 250, 0.05) 88.21%,
    rgba(236, 161, 254, 0.05) 100%
  );
  backdrop-filter: blur(20px);
  margin-left: 2.5%;
  margin-right: 2.5%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.generate_cv_text {
  overflow: hidden;
  color: #fff;
  text-overflow: ellipsis;
  font-family: "Jura";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.32px;
  text-align: left;
  margin-left: 2%;
}

.generate_cv_text-mobile {
  overflow: hidden;
  color: #fff;
  text-overflow: ellipsis;
  font-family: "Jura";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.32px;
  text-align: center;
  margin-top: 5%;
}

.generate_button {
  border-radius: 8px;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.1) 0%,
    rgba(83, 113, 196, 0.1) 11.79%,
    rgba(87, 115, 198, 0.1) 21.38%,
    rgba(93, 116, 200, 0.1) 29.12%,
    rgba(101, 119, 203, 0.1) 35.34%,
    rgba(110, 122, 207, 0.1) 40.37%,
    rgba(121, 125, 211, 0.1) 44.56%,
    rgba(133, 129, 215, 0.1) 48.24%,
    rgba(145, 133, 220, 0.1) 51.76%,
    rgba(159, 137, 225, 0.1) 55.44%,
    rgba(172, 141, 230, 0.1) 59.63%,
    rgba(186, 145, 235, 0.1) 64.66%,
    rgba(199, 150, 240, 0.1) 70.88%,
    rgba(212, 154, 245, 0.1) 78.62%,
    rgba(225, 157, 250, 0.1) 88.21%,
    rgba(236, 161, 254, 0.1) 100%
  );
  margin-left: 40vw;
  width: 245px;
  height: 40px;
  flex-shrink: 0;
}

.generate_button-mobile {
  border-radius: 8px;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.1) 0%,
    rgba(83, 113, 196, 0.1) 11.79%,
    rgba(87, 115, 198, 0.1) 21.38%,
    rgba(93, 116, 200, 0.1) 29.12%,
    rgba(101, 119, 203, 0.1) 35.34%,
    rgba(110, 122, 207, 0.1) 40.37%,
    rgba(121, 125, 211, 0.1) 44.56%,
    rgba(133, 129, 215, 0.1) 48.24%,
    rgba(145, 133, 220, 0.1) 51.76%,
    rgba(159, 137, 225, 0.1) 55.44%,
    rgba(172, 141, 230, 0.1) 59.63%,
    rgba(186, 145, 235, 0.1) 64.66%,
    rgba(199, 150, 240, 0.1) 70.88%,
    rgba(212, 154, 245, 0.1) 78.62%,
    rgba(225, 157, 250, 0.1) 88.21%,
    rgba(236, 161, 254, 0.1) 100%
  );
  width: 245px;
  height: 40px;
  flex-shrink: 0;
  margin-top: 5%;
}

.generate_courses_container {
  margin-top: 1vh;
  width: 90%;
  height: 40vh;
  border-radius: 16px;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.05) 0%,
    rgba(83, 113, 196, 0.05) 11.79%,
    rgba(87, 115, 198, 0.05) 21.38%,
    rgba(93, 116, 200, 0.05) 29.12%,
    rgba(101, 119, 203, 0.05) 35.34%,
    rgba(110, 122, 207, 0.05) 40.37%,
    rgba(121, 125, 211, 0.05) 44.56%,
    rgba(133, 129, 215, 0.05) 48.24%,
    rgba(145, 133, 220, 0.05) 51.76%,
    rgba(159, 137, 225, 0.05) 55.44%,
    rgba(172, 141, 230, 0.05) 59.63%,
    rgba(186, 145, 235, 0.05) 64.66%,
    rgba(199, 150, 240, 0.05) 70.88%,
    rgba(212, 154, 245, 0.05) 78.62%,
    rgba(225, 157, 250, 0.05) 88.21%,
    rgba(236, 161, 254, 0.05) 100%
  );
  backdrop-filter: blur(20px);
  margin-left: 5%;
  margin-right: 5%;
  flex-direction: column;
  display: flex;
}

.generate_content_container {
  margin-top: 1vh;
  width: 50%;
  height: 40vh;
  border-radius: 16px;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.05) 0%,
    rgba(83, 113, 196, 0.05) 11.79%,
    rgba(87, 115, 198, 0.05) 21.38%,
    rgba(93, 116, 200, 0.05) 29.12%,
    rgba(101, 119, 203, 0.05) 35.34%,
    rgba(110, 122, 207, 0.05) 40.37%,
    rgba(121, 125, 211, 0.05) 44.56%,
    rgba(133, 129, 215, 0.05) 48.24%,
    rgba(145, 133, 220, 0.05) 51.76%,
    rgba(159, 137, 225, 0.05) 55.44%,
    rgba(172, 141, 230, 0.05) 59.63%,
    rgba(186, 145, 235, 0.05) 64.66%,
    rgba(199, 150, 240, 0.05) 70.88%,
    rgba(212, 154, 245, 0.05) 78.62%,
    rgba(225, 157, 250, 0.05) 88.21%,
    rgba(236, 161, 254, 0.05) 100%
  );
  backdrop-filter: blur(20px);
  margin-left: 1%;
  margin-right: 5%;
  flex-direction: column;
  display: flex;
}

.content_header_info {
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
}

.content_header_text {
  color: #fff;
  font-family: "Jura";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.48px;
  margin-left: 5%;
  white-space: nowrap;
  margin-top: 2vh;
}

.content_container {
  border-radius: 8px;
  background: linear-gradient(
    135deg,
    rgba(82, 113, 196, 0.05) 0%,
    rgba(83, 113, 196, 0.05) 11.79%,
    rgba(87, 115, 198, 0.05) 21.38%,
    rgba(93, 116, 200, 0.05) 29.12%,
    rgba(101, 119, 203, 0.05) 35.34%,
    rgba(110, 122, 207, 0.05) 40.37%,
    rgba(121, 125, 211, 0.05) 44.56%,
    rgba(133, 129, 215, 0.05) 48.24%,
    rgba(145, 133, 220, 0.05) 51.76%,
    rgba(159, 137, 225, 0.05) 55.44%,
    rgba(172, 141, 230, 0.05) 59.63%,
    rgba(186, 145, 235, 0.05) 64.66%,
    rgba(199, 150, 240, 0.05) 70.88%,
    rgba(212, 154, 245, 0.05) 78.62%,
    rgba(225, 157, 250, 0.05) 88.21%,
    rgba(236, 161, 254, 0.05) 100%
  );
  width: 90%;
  height: 120px;
  flex-shrink: 0;
  margin-left: 5%;
  margin-top: 1%;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.content_img {
  height: 90%;
  width: 25%;
  border-radius: 15px;
  flex-shrink: 0;
  margin-left: 5%;
  object-fit: contain;
  align-self: center;
}

.content_title_container {
  flex-direction: column;
  display: flex;
  text-align: left;
}

.content_title_text_purp {
  color: #9e89e1;
  font-family: "Jura";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.32px;
}

.content_title_text_description {
  color: rgba(255, 255, 255, 0.8);
  font-family: "Jura";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.32px;
  text-overflow: ellipsis;
}

.explore-enter-text {
  color: #fff;
  font-family: "Jura";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.48px;
  opacity: 0.5;
  white-space: nowrap;
  display: inline;
  text-align: left;
}
