.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px, 0;
  background: #0f0c29;
  background: -webkit-linear-gradient(to left, #24243e, #302b63, #0f0c29);
  background: linear-gradient(to left, #24243e, #302b63, #0f0c29);
  color: white;
  height: 50px;
}

.header_left {
  flex: 0.3;
  display: flex;
  align-items: center;
  margin-left: 5%;
  margin-top: 1%;
}

.header_center {
  flex: 0.4;
  display: flex;
  align-items: center;
  margin-left: 60px;
}

.crewmate_logo {
  flex: 0.3;
  display: flex;
  align-items: center;
}

.header_search {
  flex: 0.4;
  background: #0f0c29; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #24243e,
    #302b63,
    #0f0c29
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #24243e,
    #302b63,
    #0f0c29
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
  display: flex;
  padding: 0, 20px;
  border: 1px, grey solid;
  border-radius: 20px;
  height: 50%;
}

.header_search > input {
  background-color: transparent;
  border: none;
  text-align: center;
  min-width: 35vw;
}

.header_right {
  flex: 0.3;
  display: flex;
  align-items: flex-end;
  margin-left: 0%;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 16px;
  justify-content: space-around;
  word-wrap: break-word;
  /* Optionally, you can set a max-width for the text container to control the wrapping behavior */
  max-width: calc(100% - 50px);
}

.header_right > .MuiSvgIcon-root {
  margin-left: auto;
  margin-right: 20px;
}
.astext {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.header_labels {
  color: #fff;
  text-align: center;
  font-family: "Jura";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
  margin-bottom: 5vh;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* Individual item */
.bm-item {
  display: inline-block;

  color: #d1d1d1;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}

.bm-item:hover {
  color: #ffffff;
}
